import de from '@/lang/de.json';
import en from '@/lang/en.json';

export default defineI18nConfig(() => ({
	legacy: false,
	locale: 'de',
	messages: {
		de,
		en,
	},
}));
